#footer .nav__link__container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

#footer .nav__link{
    transition: var(--transition);
}

#footer .nav__link:hover{
    color: var(--color-primary);
}

#footer .social__handles__container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
}

#footer .social__handles__container .social_handle{
    width: 3rem;
    font-size: 1.5rem;
}

#footer .copyright__container{
    width: 100%;
    text-align: center;
    margin-top: 70px;
}

#footer .copyright__container h3{
    margin-bottom: 1rem;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-off-white);
}
@media(max-width: 768px){
    #footer .nav__link__container{
        flex-direction: column;
    }
}