@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    --color-primary: #7CB9E8;
    --color-secondary: rgba(28, 20, 5, 0.773);
    --color-text: #fff;
    --color-muted: #9fa5a5;
    --color-success: #33c648;
    --color-blueviolet: #0096FF;
    --color-white: #ffffff;
    --color-ui-1: rgb(1,1,1);
    --color-ui-2: rgb(24, 25, 25);
    --color-off-white: #f6f3fc;
    --transition: all 400ms ease;
    --gray-border: solid 0.5px lightgray;
    --border-radius-1: 4px;
    --border-radius-2: 0.8rem;
    --border-radius-3: 1.2rem;
    --border-radius-4: 2rem;
    --border-radius-5: 51px;
    --gradient-1: linear-gradient(260deg, var(--color-secondary) 0%, var(--color-primary) 100%);
    --card-rgb: 180, 185, 188;
    --card-border-rgb: 131, 134, 135;
}

* {
    margin: 0;
    padding: 0;
    scrollbar-color: var(--color-primary) var(--color-secondary);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    scrollbar-width: thin;
    border: 0;
    outline: 0
}

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: var(--gradient-1);
}

::-webkit-scrollbar-thumb {
    --webkit-border-radius: 1ex;
    --webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: transparent;
}

body {
    min-height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    background: var(--color-ui-1);
    color: var(--color-text);
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
}

a {
    cursor: pointer;
    display: block;
    color: var(--color-text);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

img {
    min-height: 100%;
    width: 100%;
    object-fit: cover;
}

.btn {
    padding: 15px 25px;
    border-radius: var(--border-radius-5);
    color: var(--color-text);
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    background: var(--color-ui-2);
    text-align: center;
    cursor: pointer;
}

.btn__primary {
    background: var(--gradient-1);
    color: var(--color-white);
    background-size: 200% auto;
    transition: var(--transition);
}

.btn__primary:hover {
    background-position: right center;
}

.color__primary {
    color: var(--color-primary);
}

.text__muted {
    color: var(--color-muted);
}

.stroke__text {
    -webkit-text-stroke: .5px rgba(212,212,214,0.176);
    -webkit-text-fill-color: transparent;
}

.primary__title {
    font-size: 55px;
    font-weight: 900;
    margin: 20px 0;
    background: linear-gradient(to right, var(--color-primary) 25%, var(--color-white) 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.section__wrapper {
    max-width: 1190px;
    margin: auto;
    padding: 80px 20px;
}

.icon__container {
    width: 2.5rem;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-5);
    cursor: pointer;
    transition: var(--transition);
    color: var(--color-muted);
}

.icon__container:hover {
    transform: scale(1.2);
    background: var(--color-ui-2);
    color: var(--color-white);
}

.blur-effect {
    position: relative;
}

.blur-effect::before {
    content: "";
    position: absolute;
    width: 322px;
    height: 308px;
    background: var(--color-primary);
    background: linear-gradient(190deg, var(--color-primary) 0%, rgba(115, 67, 210, 0) 100%);
    -webkit-filter: blur(150px);
    filter: blur(150px);
    z-index: -1;
}

.section__header {
    width: 100%;
    margin-bottom: 20px;
}

.section__header.center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
}

.section__header.center .primary__title,
.section__header.center .description {
    text-align: center;
}

.section__header.center .description {
    max-width: 900px;
}

/* Responsiveness */

@media (max-width: 1100px) {
    .primary__title {
        font-size: 32px;
    }
}
@media (max-width: 950px) {
    .section__header {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .section__header .primary__title,
    .section__header .title {
        text-align: center;
    }

    .section__header .description {
        margin: auto;
        text-align: center;
    }
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    font-size: 60px;
    font-weight: 900;
}
.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-default .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
    display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-default .odometer-value {
    display: block;
    -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-value.odometer-last-value {
    position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
    font-family: "Helvetica Neue", sans-serif;
    line-height: 1.1em;
}
.odometer.odometer-auto-theme .odometer-value, .odometer.odometer-theme-default .odometer-value {
    text-align: center;
}

@media(max-width:1100px) {
    .odometer.odometer-auto-theme, .odometer.odometer-theme-default {
        font-size: 32px;
    }
}
@media (max-width:500px) {
    .odometer.odometer-auto-theme, .odometer.odometer-theme-default {
        font-size: 20px;
    }
}
