.skill__card{
    padding: 20px 30px;
    border: 1px solid transparent;
    transition: var(--transition);
    border-radius: var(--border-radius-3);
    background: rgba(var(--card-rgb),0.1);
}

.skill__card:hover{
    background: transparent;
    border-color: var(--color-primary);
    cursor: default;
}

.skill__card:hover .skill__content .skill__details {
    border-color: 	#181818;
}

.skill__card h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.skill__card .skill__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.1rem;
}

.skill__card .skill__details{
    display: flex;
    gap: 1rem;
}

.skill__details:hover{
    color: black;
    background-color: white;
}

.skill__card .skill__icon{
    margin-top: 6px;
    color: var(--color-primary);
}

.skill__card .skill__details .skill__name{
    font-size: 12px;
}

.skill__card .skill__details .skill__level{
    font-size: 14px;
}

.skill__card .skill__content .skill__details .skill__cell{
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.skill__card .skill__content .skill__details__article{
    padding: 12px 5px 12px 5px;
    border-radius: var(--border-radius-5);
    margin: 10px;
    transition: border-color 0.3s ease;
    display: flex;
    justify-content: space-around;
    border: 1px solid #080808;
}

@media(max-width: 350px){
    .skill__card .skill__details .skill__name{
        font-size: 12px;
    }
    .skill__card .skill__details .skill__level{
        font-size: 10px;
    }
}